// import { readonly } from "vue";

export default class {
  // static conditionalSpec(project, readOnly) {
  //   return {
  //     "view": "panels/split",
  //     "width": "matchParent",
  //     "height": "matchParent",
  //     "center": {
  //       "height": "matchParent",
  //       "childViews": [
  //         readOnly ?
  //           footer.historySpec(this.snapshotHeading, this.snapshotBody, project, this.spec.snapshotIndex) :
  //           this.snapshotLinkSpec
  //       ]
  //     },
  //     "right": {
  //       "childViews": readOnly ? [] : [
  //         this.snapshotFormSpec
  //       ]
  //     }
  //   }
  // }

  static snapshotLinkSpec(projectId, snapshotCount) {
    return {
      "view": "panels/horizontal",
      "height": "matchParent",
      "align": "middle",
      "childViews": snapshotCount > 0 ? [
        {
          "view": "label",
          "id": "snapshot_count",
          "text": `View snapshots (${snapshotCount})`,
          "onClick": {
            "action": "windows/open",
            "url": $tpu.urls.snapshot(projectId, "latest")
          },
        }
      ] : []
    }
  }

  static historySpec(snapshotHeading, snapshot) {
    // const maxIndex = project.chartSnapshots.length - 1;
    // const currentIndex = snapshotId == "latest" ? maxIndex : Number(snapshotId)

    // const currentIndex = snapshot.index

    return {
      "view": "panels/split",
      "width": "matchParent",
      "center": {
        "height": "matchParent",
        "childViews": [
          {
            "view": "panels/vertical",
            "width": "matchParent",
            "padding": { bottom: 10 },
            "childViews": [
              {
                "view": "h4",
                "text": snapshotHeading
              },
              {
                "view": "spacer",
                "height": 4
              },
              {
                "view": "label",
                "text": snapshot.description
              }
            ]
          }
        ]
      },
      "right": {
        "height": "matchParent",
        "childViews": [
          {
            "view": "panels/horizontal",
            "childViews": [
              {
                "view": "button",
                "text": "Older",
                "styleClasses": ["tonal", "small"],
                "disabled": !snapshot.previousUrl,
                "onClick": {
                  "action": "windows/open",
                  "url": snapshot.previousUrl
                },
              },
              {
                "view": "spacer",
                "width": 4
              },
              {
                "view": "button",
                "text": "Newer",
                "styleClasses": ["tonal", "small"],
                "disabled": !snapshot.nextUrl,
                "onClick": {
                  "action": "windows/open",
                  "url": snapshot.nextUrl
                },
              }
            ]
          }
        ]
      }
    }
  }
}
